import React, { useState } from "react";
import Buttoon from "../components/buttoon";
import userIcon from "../assets/icons/userIcon.svg";
import pageIcon from "../assets/icons/mm.svg";
import document from "../assets/icons/contract.svg";
import DashboardChatArea from "../components/messagebox";
import { Icon } from "@iconify/react";
import chatBot from "../assets/icons/chotBotIcon.svg";
import uploadIcon from "../assets/icons/uploadCloud.svg";
import cross from "../assets/icons/cross.svg";
import { InboxOutlined } from "@ant-design/icons";
import msgSendIcon from "../assets/icons/msgSendIcon.svg";
import greenTick from "../assets/icons/greenTick.png";

const AmlPolicy = () => {
  const [clickedIcons, setClickedIcons] = useState({
    like: false,
    heart: false,
    dislike: false,
  });

  const handleClick = (iconName) => {
    setClickedIcons({
      like: false,
      heart: false,
      dislike: false,
      [iconName]: true,
    });
  };

  return (
    <>
      <div className="chat pe-5">
        <div className="kycWriting d-flex justify-content-between align-items-center">
          <h3>PEP Policy</h3>
          <Buttoon
            className="btn btn-primary"
            minWidth={208}
            minHeight={39}
            text="Save Document"
          ></Buttoon>
        </div>
        <hr />
        <div className="chat-data d-flex flex-column justify-content-between">
          <div className="chattname">
            <img className="pageIcon" src={pageIcon} alt="PageIcon" />
            <div className="chatting">
              <h4>ChatKYC</h4>
              <h5>Document Completeness:</h5>
              <ul>
                <li className="d-flex v-center gap-1">
                  Status: Complete <img src={greenTick} />
                </li>
                <li>
                  Details: The form is fully completed with all required fields
                  filled in. This includes the client's personal details,
                  contact information, and identification numbers.
                </li>
              </ul>
              <h5>Identification Details:</h5>
              <ul>
                <li className="d-flex v-center gap-1">
                  Status: Correct <img src={greenTick} />
                </li>
                <li>
                  Details: All required identification details are present and
                  correctly formatted. The personal identification number, date
                  of birth, and other critical identifiers match the standard
                  requirements.
                </li>
              </ul>
              <h5>Recommendations:</h5>
              <ul>
                <li>
                  Proof of Address: Acceptable documents include a recent
                  utility bill, bank statement, or any official document that
                  shows your address.
                </li>
                <li>
                  Photo ID: Acceptable documents include a passport, driver’s
                  license, or national ID card.
                </li>
              </ul>
              <div className="d-flex gap-2 mt-4">
                <span
                  className={`icon-container ${
                 clickedIcons.like ? "clicked cursor-pointer" : "unclicked cursor-pointer"
                  }`}
                  onClick={() => handleClick("like")}
                >
                  <Icon icon="iconamoon:like" width="20" height="20" />
                </span>

                <span
                  className={`icon-container ${
                    clickedIcons.heart ? "clicked cursor-pointer" : "unclicked cursor-pointer"
                  }`}
                  onClick={() => handleClick("heart")}
                >
                  <Icon icon="mdi:heart-outline" width="20" height="20" />
                </span>

                <span
                  className={`icon-container ${
                         clickedIcons.dislike ? "clicked cursor-pointer" : "unclicked cursor-pointer"
                  }`}
                  onClick={() => handleClick("dislike")}
                >
                  <Icon icon="iconamoon:dislike" width="20" height="20" />
                </span>
              </div>
            </div>
          </div>
          <hr />

          <div className="chattname docUpload">
            <img className="pageIcon" src={userIcon} alt="UserIcon" />
            <div className="chatting">
              <h4>You</h4>
              <div className="box d-flex">
                <img src={document} alt="Icon" />
                <p>Financial_Crime_Policy_ Template.docx</p>
              </div>
            </div>
          </div>
        </div>
        <DashboardChatArea />
      </div>
    </>
  );
};

export default AmlPolicy;
