import React, { useState, useEffect, useRef } from "react";
import './pages/home/DashboardChatArea.css';
import promptClip from "../assets/icons/promptClip.svg";
import { useNavigate, useParams } from 'react-router-dom';
import { message, Modal, Progress, Spin } from 'antd';
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import uploadIcon from '../assets/icons/uploadCloud.svg';
import paperPlane from '../assets/icons/paperPlain.svg';
import chatKycLogo from "../assets/icons/kycLogo.svg";
import verifyUser from '../middleware/verifyUser';
import axios from 'axios';
import Buttoon from '../components/buttoon';

const DashboardChatArea = ({ setShowContent }) => {
  const [chatMessage, setChatMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [loadingIndex, setLoadingIndex] = useState(null);  // Tracks which message is loading
  const [userInitials, setUserInitials] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadTimeout, setUploadTimeout] = useState(null);
  const [isUser, setIsUser] = useState(false);
  const [userId, setUserId] = useState('');
  const chatEndRef = useRef(null);
  const typingEffectIntervalRef = useRef(null);
  const [chat_id, setChat_id] = useState(null);
  const [hasChatHistory, setHasChatHistory] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      setChat_id(id);
      fetchChatHistory();
    }
  }, [id]);

  useEffect(() => {
    const LoginUserId = localStorage.getItem('userId');
    setUserId(LoginUserId);

    const checkUser = async () => {
      const result = await verifyUser();
      if (!result) {
        navigate('/login');
      } else {
        setIsUser(true);
      }
    };
    checkUser();

    const name = localStorage.getItem('name');
    if (name) {
      const nameParts = name.split(' ');
      const initials = nameParts[0][0].toUpperCase() + (nameParts[1] ? nameParts[1][0].toUpperCase() : '');
      setUserInitials(initials);
    }

    if (chat_id) {
      fetchChatHistory();
    }
  }, [chat_id, navigate]);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatHistory]);

  const fetchChatHistory = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/chatApi/chat/${chat_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const fetchedHistory = response.data.chats;

      const formattedHistory = fetchedHistory.flatMap((chat) =>
        chat.conversations.map((conversation) => ({
          user: conversation.user || '', // Get user message
          bot: conversation.bot || '',   // Get bot message
          timestamp: conversation.timestamp || '', // Optional: Add timestamp
        }))
      );

      setChatHistory(formattedHistory);
      setHasChatHistory(formattedHistory.length > 0);
      if (formattedHistory.length > 0) {
        setShowContent(false);

      } else {
        setShowContent(true);

      }


    } catch (error) {
      console.error('Error fetching chat history:', error);
      message.error('Failed to fetch chat history.');
    }
  };

  const handleSendMessage = async () => {
    if (!selectedFile && chatMessage.trim() === '') {
      message.warning('Please enter a message or select a file to upload.');
      return;
    }

    const currentMessageIndex = chatHistory.length;
    let newHistoryItem = {
      user: chatMessage || selectedFile?.name || 'File uploaded',
      bot: '',
    };

    if (selectedFile && chatMessage.trim() !== '') {
      newHistoryItem.user = `${selectedFile.name}\n${chatMessage}`;
    }

    // Add user's message to chat history
    setChatHistory((prevHistory) => [...prevHistory, newHistoryItem]);
    setLoadingIndex(currentMessageIndex);
    setShowContent(false);

    try {
      if (selectedFile) {
        // Handle file upload if a file is selected
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('chat_id', chat_id);
        formData.append('user_id', userId);
        formData.append('query', chatMessage || ''); // Include the message if any

        const fileUploadResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL_PHYTHON}/upload_document`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setProgress(percentCompleted);
            },
          }
        );

        const botMessage = fileUploadResponse.data.message;
        const botResponse = fileUploadResponse.data.response;

        // Show typing effect for bot response
        let messageIndex = 0;
        typingEffectIntervalRef.current = setInterval(() => {
          setChatHistory((prevHistory) => {
            const updatedArray = [...prevHistory];
            updatedArray[currentMessageIndex].bot = botMessage.substring(0, messageIndex);
            return updatedArray;
          });
          messageIndex++;
          if (messageIndex > botMessage.length) {
            clearInterval(typingEffectIntervalRef.current);
            typingEffectIntervalRef.current = null;

            // Append the bot's full response after typing effect
            setChatHistory((prevHistory) => [...prevHistory, { bot: botResponse }]);
            setLoadingIndex(null); // Hide the loader after response
            chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
          }
        }, 15);

        setUploading(false);
        setSelectedFile(null);
        resetFileInput();  // Clear file input
        setChatMessage('');  // Clear message input
      }

      if (chatMessage.trim() !== '' && !selectedFile) {
        // Handle chat message without file upload
        const payload = {
          chat_id: chat_id,
          prompt: chatMessage,
          user_id: userId,
        };

        const chatResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL_PHYTHON}/chat`,
          payload,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        const botResponse = chatResponse.data.response;
        let responseIndex = 0;

        // Show typing effect for chat message
        typingEffectIntervalRef.current = setInterval(() => {
          setChatHistory((prevHistory) => {
            const updatedArray = [...prevHistory];
            updatedArray[currentMessageIndex].bot = botResponse.substring(0, responseIndex);
            return updatedArray;
          });
          responseIndex++;
          if (responseIndex > botResponse.length) {
            clearInterval(typingEffectIntervalRef.current);
            typingEffectIntervalRef.current = null;
            setLoadingIndex(null); // Stop the loader once the response is done
            chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
          }
        }, 15);

        setChatMessage('');  // Clear input after sending message
      }

    } catch (error) {
      setUploading(false);
      setLoadingIndex(null);  // Hide loader in case of error
      message.error('Failed to process the request. Please try again.');
      console.error('Error uploading file or sending message:', error);
    }
  };


  const resetFileInput = () => {
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.value = '';
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
      setChatMessage('');

    }
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setSelectedFile(file);
      message.success(`${file.name} file selected.`);
    }
  };

  const parseMarkdown = (text) => {
    if (typeof text !== 'string') {
      return '';
    }

    text = text.replace(/^# (.+)$/gm, '<h1 style="color: black; font-weight: normal;">$1</h1>');
    text = text.replace(/^## (.+)$/gm, '<h2 style="color: black; font-size:1.5em; font-weight: normal;"><strong>$1</strong></h2>');
    text = text.replace(/^### (.+)$/gm, '<h3 style="color: black; font-size:1.5em; font-weight: normal;"><strong>$1</strong></h3>');
    text = text.replace(/^#### (.+)$/gm, '<h4 style="color: black; font-size:1.2em; font-weight: bold;">$1</h4>');

    // Bold and italic text
    text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    text = text.replace(/\*(.*?)\*/g, '<em>$1</em>');

    // Lists
    text = text.replace(/-\s(.+?)\n/g, '<li>$1</li>');

    // Links
    text = text.replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2" target="_blank">$1</a>');

    // Ordered list items
    text = text.replace(/(\d+\.\s.+?:)/g, '<strong style="font-size: 1em; color:#463f3e;">$1</strong>');

    text = text.replace(/\|(.+?)\|/g, (match) => {
      const rows = match.trim().split('\n');
      const tableRows = rows.map((row) => {
        const columns = row.split('|').filter(col => col.trim() !== '');
        const formattedCols = columns.map(col => `<td>${col.trim()}</td>`).join('');
        return `<tr>${formattedCols}</tr>`;
      });
      return `<table border="1" style="border-collapse: collapse;">${tableRows.join('')}</table>`;
    });

    text = text.replace(/\n/g, '<br/>');

    return text;
  };


  if (!isUser) {
    return null;
  }

  // Loader icon for loading state
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <>
      <div className="chatArea">
        <div className="chatWithBot">
          {chatHistory.map((chat, index) => (
            <div
              key={index}
              className={`chatMessageContainer ${chat.user ? 'userMessage' : 'botMessage'}`}
            >
              {chat.user && (
                <div className="userInitials">
                  {userInitials}
                </div>
              )}

              <div className={`chatBubble ${chat.user ? '' : 'botChatBubble'}`}>
                <p
                  style={{
                    margin: 0,
                    opacity: index === loadingIndex ? 0.5 : 1,
                  }}
                >
                  {chat.user ? (
                    <>
                      {chat.user}
                      {index === loadingIndex && (
                        <div style={{ display: 'inline-block', marginLeft: '13px' }}>
                          <Spin indicator={antIcon} className="loader-spin" />
                        </div>
                      )}
                    </>
                  ) : chat.bot}
                </p>
              </div>

              {chat.bot && (
                <div className="botResponse">
                  <img className="botMessageLogo" src={chatKycLogo} alt="ChatKYC Logo" />
                  <div className="botChatBubble">
                    <p dangerouslySetInnerHTML={{ __html: parseMarkdown(chat.bot) }} />
                  </div>
                </div>
              )}
            </div>
          ))}
          <div ref={chatEndRef} />
        </div>

        <div className="promptDiv">
          {selectedFile && (
            <div className="selectedFileName">
              <p>{selectedFile.name}</p>
            </div>
          )}
          <div className="promptArea1">
            <label htmlFor="fileInput">
              <img className="cursor-pointer" src={promptClip} alt="Upload" />
            </label>
            <input
              id="fileInput"
              type="file"
              accept=".pdf,.csv,.pptx,.docx,.txt"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
          </div>
          <div className="promptArea2">
            <input
              className="text-med"
              type="text"
              placeholder="Message ChatKYC"
              value={chatMessage}
              onKeyPress={handleKeyPress}
              onChange={(e) => setChatMessage(e.target.value)}
            />
          </div>
          <div className="promptArea3">
            <img className="cursor-pointer" src={paperPlane} alt="Send" onClick={handleSendMessage} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardChatArea;
