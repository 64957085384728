import React from "react";
import Navbar from "../shared/navbar";
import Footer from "../shared/footer";
import Hero from "../components/pages/home/hero";
import Features from "../components/pages/home/features";
import Benefits from "../components/pages/home/benefits";
import OurUsers from "../components/pages/home/ourUsers";
import Money from "../components/pages/home/money";
import Faq from "../components/pages/home/faq";

const Landingpage = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <Features />
      <Benefits />
      <OurUsers />
      <Money />
      <Faq />
      <Footer />
    </>
  );
};

export default Landingpage;
