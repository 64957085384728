import React, { useState } from "react";
import { Modal } from "antd";
import Input from "../../shared/input";
import Buttoon from "../../components/buttoon";
import axios from "axios";
import Swal from "sweetalert2";

const ChangeEmailModal = ({ isEmailModal, setIsEmailModal, fetchLoginUser }) => {
  const [newEmail, setNewEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    setIsEmailModal(false);
    console.log("Modal should close now");
  };

  const handleChangeEmail = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/update_email`,
        { email: newEmail },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Email updated successfully", response.data);
      setLoading(false);
      fetchLoginUser(); // Refresh user data
      handleCancel();
      Swal.fire({
        title: "Success",
        text: "Your email has been updated successfully!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.error("Error updating email:", error);
      setLoading(false);
      Swal.fire({
        title: "Error",
        text: "There was an error updating your email. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <Modal
      open={isEmailModal}
      className="credit-modal"
      centered={true}
      onCancel={handleCancel}
      footer={null}
    >
      <div className="modal-content">
        <div className="pass-modal-header w-100">
          <h4 className="fw-bold">Change Email</h4>
        </div>
        <div className="pass-modal-content">
          <div className="c-pass-div h-center justify-content-start flex-column gap-2">
            <label className="fw-bold" htmlFor="">
              Enter Your Email
            </label>
            <Input
              height="46px"
              type="email"
              className="curnt-pass w-100"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
            />
          </div>

          <div className="h-center">
            <Buttoon
              className="btn btn-primary mt-5 m-auto"
              minWidth={166}
              minHeight={43}
              text="Save"
              onClick={handleChangeEmail} 
              loading={loading}
            ></Buttoon>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeEmailModal;
