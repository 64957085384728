import React, { useState, useEffect, useRef } from "react";
import { Icon } from "@iconify/react";
import threedots from "../assets/icons/3dots.svg";
import DelCardModal from "../dashboard/modals/delCardModal";

const ThreeDots = ({ chatId, handleDeleteChat }) => {
  const dropRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Close the popup when clicking outside
  const handleClickOutside = (event) => {
    if (dropRef.current && !dropRef.current.contains(event.target)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Toggle the three-dots menu
  const handletoggle = () => {
    setShowPopup(!showPopup);
  };

  // Show the delete confirmation modal
  const showDelModal = () => {
    setIsModalOpen(true);
  };

  // Handle the delete confirmation
  const handleConfirmDelete = () => {
    handleDeleteChat(chatId); // Call the delete function with chatId
    setIsModalOpen(false); // Close modal
  };

  return (
    <>
      <div className="rightArea" ref={dropRef}>
        <img
          src={threedots}
          alt="three dots"
          className="cursor-pointer"
          onClick={handletoggle}
        />
        {showPopup && (
          <div className="editDiv position-absolute">
            <li className="cursor-pointer v-center justify-content-left gap-2 mb-3">
              <Icon
                icon="la:pen"
                width="20"
                height="20"
                style={{ color: "black" }}
              />
              <span className="cursor-pointer text-small fw-medium">
                Rename
              </span>
            </li>
            <li
              className="cursor-pointer v-center justify-content-left gap-2"
              onClick={showDelModal}
            >
              <Icon
                icon="ph:trash"
                width="19"
                height="19"
                style={{ color: "red" }}
              />
              <span className="cursor-pointer text-danger text-small fw-medium">
                Delete
              </span>
            </li>
          </div>
        )}
      </div>

      {/* Modal to confirm delete */}
      <DelCardModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onConfirm={handleConfirmDelete} // Pass the confirm delete function
      />
    </>
  );
};

export default ThreeDots;
