import React from "react";
import Heading from "../../../shared/heading";
import Accordion from "react-bootstrap/Accordion";
import blueGlow from "../../../assets/images/home/blueGlow.webp";
import purpleGlow from "../../../assets/images/home/benefitGlow.webp";

const Faq = () => {
  return (
    <>
      <div name="faq" className="faqSection">
        <img className="blueGlow" src={blueGlow} alt="" />
        <img className="purpleGlow" src={purpleGlow} alt="" />
        <div className="container">
          <Heading
            headingtext="FAQ's"
            paraText="Have questions? We've got answers. Explore our FAQ section to find quick and clear responses to the most commonly asked questions about our AI-powered compliance tool."
          />

          <div className="faq-container">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header className="accordianHeader">
                  <b>Q:</b>&nbsp; What is the scope of compliance covered by
                  ChatKYC?
                </Accordion.Header>
                <Accordion.Body>
                  <b>A:</b>&nbsp; ChatKYC goes beyond just KYC compliance to
                  encompass a wide range of compliance needs. Whether you’re
                  navigating financial regulations, privacy laws, ESG, or
                  corporate governance, our tool provides comprehensive support
                  across various domains of compliance.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <b>Q:</b>&nbsp; Who can benefit from using ChatKYC?
                </Accordion.Header>

                <Accordion.Body>
                  <b>A:</b>&nbsp; ChatKYC is a powerful compliance tool, but
                  it’s not just for compliance professionals! It’s intuitive
                  design and ease of use, make it accessible to anyone. Whether
                  you’re a startup founder, a small business owner, an educator,
                  or someone exploring compliance requirements for the first
                  time, our platform can address your compliance needs and
                  queries.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <b>Q:</b>&nbsp;Do I need to have compliance expertise to use
                  ChatKYC?
                </Accordion.Header>
                <Accordion.Body>
                  <b>A:</b>&nbsp; Not at all! ChatKYC is designed to be
                  user-friendly, guiding users through complex compliance
                  landscapes without requiring prior compliance knowledge. With
                  simple inputs, you can receive summarizations, risk
                  assessments, and compliance guidance tailored to your specific
                  needs.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <b>Q:</b>&nbsp; What regions are covered by ChatKYC?
                </Accordion.Header>
                <Accordion.Body>
                  <b>A:</b>&nbsp; ChatKYC is trained on an array of regulations,
                  industry standards, and best practices across multiple
                  jurisdictions including America, Canada, the EU, UK, Dubai,
                  Abu Dhabi, Singapore, Australia and many more. We are
                  continuously updating our database to include new regulations
                  and standards to ensure comprehensive global compliance
                  coverage.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <b>Q:</b>&nbsp;Can ChatKYC adapt to regulations from multiple
                  countries for multinational operations?
                </Accordion.Header>
                <Accordion.Body>
                  <b>A:</b>&nbsp;Yes, absolutely. ChatKYC is equipped to handle
                  compliance for multinational operations, offering insights and
                  guidance based on regulations from various jurisdictions. This
                  feature is particularly beneficial for businesses and ventures
                  operating across borders, ensuring consistent compliance
                  across all locations.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <b>Q:</b>&nbsp;How does ChatKYC stay updated with the latest
                  regulations and standards?
                </Accordion.Header>
                <Accordion.Body>
                  <b>A:</b>&nbsp; ChatKYC leverages advanced AI technology to
                  continuously monitor changes in regulations, industry
                  standards, and best practices. This ensures it remains
                  up-to-date with the latest compliance requirements across all
                  the regions we cover.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  <b>Q:</b>&nbsp;Is ChatKYC suitable for industries beyond
                  banking, fintechs and Crypto?
                </Accordion.Header>
                <Accordion.Body>
                  <b>A:</b>&nbsp; Yes. While ChatKYC offers specialized tools
                  and insights for the banking, fintech and Crypto sectors, its
                  capabilities extend to wider finance including but not limited
                  to, hedge funds, capital markets and asset managers. ChatKYC
                  has been developed with the vision to provide AI powered
                  assistance to any regulated industry. From healthcare to
                  manufacturing, ecommerce to education, ChatKYC can be
                  customized to meet the specific compliance needs of diverse
                  sectors.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
