import React, { useEffect, useState } from "react";
import eyeOpen from "../../../assets/icons/eye-opeen.png";
import eyeClose from "../../../assets/icons/eye-close.svg";
import ChangepassModal from "../../modals/changepassModal";
import ChangeDepModal from "../../modals/changeDepModal";
import ChangeName from "../../modals/changeName";
import ChangeEmailModal from "../../modals/changeEmailModal";
import Input from "../../../shared/input";
import axios from "axios";

const Account = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [ispswdmodal, setispswdmodal] = useState(false);
  const [User, setUser] = useState(null);
  const [isnamemodal, setisnamemodal] = useState(false);
  const [isdepModalOpen, setIsdepModalOpen] = useState(false);
  const [isEmailModal, setIsEmailModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const showNameModal = () => {
    setispswdmodal(true);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const showuserNameModal = () => {
    setisnamemodal(true);
  };

  const showDepModal = () => {
    setIsdepModalOpen(true);
  };

  const showEmailModal = () => {
    setIsEmailModal(true);
  };

  const fetchLoginUser = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/fetch_login_user`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data && response.data.data) {
        setUser(response.data.data);
      } else {
        console.error("Data not found in response. Response structure:", response.data);
      }
    } catch (error) {
      if (error.response) {
        console.error("Error Response Headers:", error.response.headers);
      } else if (error.request) {
        console.error("Error Request:", error.request);
      } else {
        console.error("Error Message:", error.message);
      }
    } finally {
      setIsLoading(false); // Ensure loading state is set to false after data fetch
    }
  };

  useEffect(() => {
    fetchLoginUser();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; // Show loading spinner or message while data is being fetched
  }

  return (
    <>
      <div className="accContainer">
        <div className="manageDiv d-flex flex-column gap-2 justify-content-left pb-3 ">
          <p className="fw-semibold">Account</p>
          <p className="fw-medium">Manage your account information</p>
        </div>

        <div className="profileBox d-flex flex-column gap-2 justify-content-left pb-3 mt-5">
          <p className="fw-semibold">Profile</p>
          <div className="profileData w-100 d-flex justify-content-between">
            <p className="mt-2">{User && User.username}</p>
            <p onClick={showuserNameModal} className="text-primary fw-medium cursor-pointer">
              Change Name
            </p>
          </div>

          {/* <div className="profileData w-100 d-flex justify-content-between">
            <p className="">{User && User.department}</p>
            <p className="text-primary fw-medium cursor-pointer" onClick={showDepModal}>
              Change Department
            </p>
          </div> */}
        </div>

        <div className="emailBox d-flex flex-column gap-2 justify-content-left pb-3 ">
          <div className="profileData w-100 d-flex justify-content-between">
            <p className="fw-semibold">Email Addresses</p>
            <p onClick={showEmailModal} className="cursor-pointer text-primary fw-medium">
              Add New Email Address
            </p>
          </div>

          <div className="profileData w-100 d-flex justify-content-between">
            <p className="">{User && User.email}</p>
            <p className="text-danger fw-medium">Remove Email Address</p>
          </div>
        </div>

        <div className="passwordBox d-flex flex-column gap-2 justify-content-left pb-3 ">
          <div className="profileData w-100 d-flex justify-content-between">
            <p className="fw-semibold">Password</p>
            <p onClick={showNameModal} className="cursor-pointer text-primary fw-medium">
              Change Password
            </p>
          </div>

          <Input
            wrapperClass="w-50 mt-2"
            placeholder="password"
            value={'*********'}
            inputClass="fw-medium text-black pe-5 accPasDiv"
            minWidth="338px"
            height="46px"
            type="password"
            icon
          />
        </div>
      </div>
      <ChangepassModal ispswdmodal={ispswdmodal} setispswdmodal={setispswdmodal} fetchLoginUser={fetchLoginUser} />
      <ChangeDepModal isdepModalOpen={isdepModalOpen} setIsdepModalOpen={setIsdepModalOpen} fetchLoginUser={fetchLoginUser} />
      <ChangeName isnamemodal={isnamemodal} setisnamemodal={setisnamemodal} fetchLoginUser={fetchLoginUser} />
      <ChangeEmailModal isEmailModal={isEmailModal} setIsEmailModal={setIsEmailModal} fetchLoginUser={fetchLoginUser} />
    </>
  );
};

export default Account;
