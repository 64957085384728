import React, { useRef, useState } from "react";
import Heading from "../../../shared/heading";
import Slider from "react-slick";
import UserCard from "./userCard";
import arrowLeft from "../../../assets/icons/arrow-left.png";

import userImg1 from "../../../assets/images/home/userCardImg1.webp";
import userImg2 from "../../../assets/images/home/userCardImg2.png";
import userImg3 from "../../../assets/images/home/userCardImg3.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const OurUsers = () => {
  const testiRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(1);

  const handleNext = () => {
    testiRef.current.slickNext();
  };

  const handlePrev = () => {
    testiRef.current.slickPrev();
  };

  const settings = {
    centerMode: true,
    centerPadding: "0px",
    slidesToShow: 3,
    initialSlide: 1, // Start at the second slide
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
    },
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "140px",
          slidesToShow: 2.3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 600,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div   className="ourUserSection" name="Testimonials">
      <Heading
        headingtext="What Our Users Say"
        paraText="Include quotes from early adopters within the fintech and blockchain spaces, focusing on how the tool has streamlined their compliance processes, saved time, and reduced errors."
      />
      <div className="inner">
        <Slider {...settings} ref={testiRef}>
          {sliderData.map((data, index) => (
            <div
              key={index}
              className={`slick-slide ${
                currentSlide === index ? "slick-center" : ""
              }`}
            >
              <UserCard data={data} showReadMore={index === 0} />
            </div>
          ))}
        </Slider>

        {/* {sliderData.map((data, index) => (
          <div key={index}>
            <UserCard data={data} />
          </div>
        ))} */}
      </div>
      <div className="gap-3 mt-8 arrow-slider-btns">
        <button className="testi-prev-btn" onClick={handlePrev}>
          <img src={arrowLeft} alt="leftarrow" />
        </button>
        <button className="testi-next-btn" onClick={handleNext}>
          <img src={arrowLeft} alt="leftarrow" />
        </button>
      </div>
    </div>
  );
};

export default OurUsers;

const sliderData = [
  {
    id: 1,
    sliderDate: "May 8, 2024",
    personName: "John Doe",
    details:
      "As a Compliance Officer at XYZ Financial, I've always struggled with keeping up-to-date with the ever-changing AML regulations. Our compliance processes were slow and cumbersome, often requiring extensive manual research and multiple iterations of policy documents. Since integrating this AI-powered compliance tool, our workflow has transformed completely.",
    userImg: userImg2,
  },
  {
    id: 2,
    sliderDate: "June 14, 2024",
    personName: "Allice",
    details:
      "As a Compliance Officer at XYZ Financial, I've always struggled with keeping up-to-date with the ever-changing AML regulations. Our compliance processes were slow and cumbersome, often requiring extensive manual research and multiple iterations of policy documents. Since integrating this AI-powered compliance tool, our workflow has transformed completely.",
    userImg: userImg1,
  },
  {
    id: 3,
    sliderDate: "Sept 22, 2024",
    personName: "Alex",
    details:
      "As a Compliance Officer at XYZ Financial, I've always struggled with keeping up-to-date with the ever-changing AML regulations. Our compliance processes were slow and cumbersome, often requiring extensive manual research and multiple iterations of policy documents. Since integrating this AI-powered compliance tool, our workflow has transformed completely.",
    userImg: userImg3,
  },
  {
    id: 4,
    sliderDate: "Sept 22, 2024",
    personName: "Alex",
    details:
      "As a Compliance Officer at XYZ Financial, I've always struggled with keeping up-to-date with the ever-changing AML regulations. Our compliance processes were slow and cumbersome, often requiring extensive manual research and multiple iterations of policy documents. Since integrating this AI-powered compliance tool, our workflow has transformed completely.",
    userImg: userImg1,
  },
];
