import React, { useState, useEffect } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Drawer, Modal } from "antd";
import finalLogo from "../assets/icons/kycLogo.svg";
import Buttoon from "./buttoon";
import btnIcon from "../assets/icons/btnIcon.svg";
import ThreeDots from "../shared/threeDots";
import PricingUpgrade from "./pricing/pricingUpgarde";
import axios from "axios";

const Sidebar = ({ isDrawerOpen, toggleDrawer, active, setActive }) => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]); // Stores chat data
  const [isModalOpen, setIsModalOpen] = useState(false); // Pricing modal state

  // Fetch user's chats
  useEffect(() => {
    const fetchUserChats = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/chatApi/chat`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUserData(response.data.data.reverse()); // Show latest chats first
      } catch (error) {
        console.error("Error fetching user chats:", error);
      }
    };
    fetchUserChats();
  }, []);

  // Create a new chat and navigate to it
  const handleNewChatClick = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_PHYTHON}/new_chat`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const chatId = response.data.chat_id;
      localStorage.setItem("chat_id", chatId); // Store new chat ID
      setUserData((prevUserData) => [chatId, ...prevUserData]); // Add new chat to the list
      navigate(`/dashboard/${chatId}`); // Navigate to the new chat
    } catch (error) {
      console.error("Failed to create a new chat:", error);
    }
  };

  // Delete a chat and create a new one after deletion
  const handleDeleteChat = async (chatId) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/chatApi/deletechat`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: { chat_id: chatId }, // Pass chat_id in DELETE request
        }
      );
      // Update UI after chat deletion
      setUserData((prevUserData) => prevUserData.filter((id) => id !== chatId));
      
      // After deletion, create a new chat and navigate to it
      const createResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL_PHYTHON}/new_chat`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const newChatId = createResponse.data.chat_id;
      localStorage.setItem("chat_id", newChatId); // Store new chat ID
      setUserData((prevUserData) => [newChatId, ...prevUserData]); // Add new chat to the list
      navigate(`/dashboard/${newChatId}`); // Navigate to the new chat

    } catch (error) {
      console.error("Failed to delete chat and create a new chat:", error);
    }
  };

  // Function to show pricing modal
  const showModal = () => {
    setIsModalOpen(true);
  };

  // Function to handle closing modal
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="sidebar-main-container d-flex flex-column">
        <div className="scrollable">
          <div className="sidebar-logo-container d-flex justify-content-start w-100">
            <img src={finalLogo} alt="logo" className="logo" />
          </div>

          {/* Button to create a new chat */}
          <Buttoon
            className="btn btn-primary w-100 newChatIcon d-flex v-center"
            minWidth={208}
            minHeight={39}
            img={btnIcon}
            text="New Chat"
            imgClass="newChatIcon"
            onClick={handleNewChatClick}
          />

          {/* Trigger Pricing Modal */}
          <div
            onClick={showModal}
            className="d-flex flex-column gap-1 pricePopup mt-3 cursor-pointer"
          >
            <p style={{ fontSize: "12px" }}>You have used 3 of 3 free chats.</p>
            <div className="coloredDiv"></div>
            <p style={{ fontSize: "12px" }} className="text-primary">
              Upgrade for less than $7 / month
            </p>
          </div>

          {/* Display User's Chat List */}
          <div className="sidebar-options-container d-flex flex-column space-between">
            <div>
              <div className="myChatBox">
                <p className="text-small fw-bold text-left">My Chats</p>
                <hr />
                <p className="todayClr text-left text-small fw-normal my-2">
                  Today's
                </p>
                <div className="mainList">
                  {userData.map((value, index) => (
                    <div
                      key={value}
                      className="text-small fw-medium d-flex align-items-center"
                      style={{
                        marginBottom:
                          index !== userData.length - 1 ? "10px" : "0",
                      }}
                    >
                      <Icon
                        icon="circum:chat-2"
                        width="20"
                        height="20"
                        style={{ color: "gray", marginRight: "8px" }}
                      />
                      <Link to={`/dashboard/${value}`}>
                        {value.substring(0, 22)}
                      </Link>
                      {/* Three Dots Menu to Delete Chat */}
                      <ThreeDots
                        chatId={value}
                        handleDeleteChat={handleDeleteChat}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="myChatBox">
                <p className="text-small fw-bold text-left">My Files</p>
                <hr />
                <p className="todayClr text-left text-small fw-normal my-2">
                  Today's
                </p>
                <div className="mainList d-flex justify-content-between v-center">
                  <NavLink to="/aml-policy" className="text-black">
                    <div className="leftArea d-flex gap-2">
                      <Icon
                        icon="mdi:paper-edit-outline"
                        width="20"
                        height="20"
                        style={{ color: "gray" }}
                      />
                      <span className="text-small fw-medium">PEP Policy</span>
                    </div>
                  </NavLink>
                </div>

                <p className="todayClr text-left text-small fw-normal my-2">
                  Yesterday's
                </p>
                <div className="mainList d-flex justify-content-between v-center">
                  <NavLink to="/sanctionCheck" className="text-black">
                    <div className="leftArea d-flex gap-2">
                      <Icon
                        icon="mdi:paper-edit-outline"
                        width="20"
                        height="20"
                        style={{ color: "gray" }}
                      />
                      <span className="text-small fw-medium">
                        Sanctions Check
                      </span>
                    </div>
                  </NavLink>
                </div>

                <div className="mainList d-flex justify-content-between v-center mt-2">
                  <NavLink to="/fccTest" className="text-black">
                    <div className="leftArea d-flex gap-2">
                      <Icon
                        icon="mdi:paper-edit-outline"
                        width="20"
                        height="20"
                        style={{ color: "gray" }}
                      />
                      <span className="text-small fw-medium">FCC Testing</span>
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>

            <NavLink className="accLinkColr text-black" to="/settings/profile">
              <div className="accSetting py-3">
                <div className="v-center gap-2 bottom-link">
                  <Icon
                    icon="ion:settings-outline"
                    width="24"
                    height="24"
                    style={{ color: "#0085FF" }}
                  />
                  <span className="accText fw-bold text-small">
                    Account Settings
                  </span>
                </div>
              </div>
            </NavLink>
          </div>
        </div>
      </div>

      {/* Mobile Sidebar */}
      <div className="mobile-sidebar">
        <Drawer
          placement="left"
          closable={false}
          onClose={toggleDrawer}
          open={isDrawerOpen}
          width={350}
          className="mobile-menu-drawer"
        >
          <div className="sidebar-container d-flex flex-column">
            <div className="scrollable">
              <div className="sidebar-logo-container mb-2">
                <Link to="#">
                  <img src={finalLogo} alt="logo" className="logo" />
                </Link>
              </div>

              <Buttoon
                className="btn btn-primary w-100 newChatIcon d-flex v-center"
                minWidth={208}
                minHeight={39}
                img={btnIcon}
                text="New Chat"
                imgClass="newChatIcon"
                onClick={handleNewChatClick}
              />

              <div
                onClick={showModal}
                className="d-flex flex-column gap-1 pricePopup my-3 cursor-pointer"
              >
                <p style={{ fontSize: "12px" }}>
                  You have used 3 of 3 free chats.
                </p>
                <div
                  style={{
                    width: "100%",
                    height: "8px",
                    backgroundImage:
                      "linear-gradient(92deg, #009EFF 0.8%, #9360FF 98.13%)",
                    borderRadius: "5px",
                  }}
                />
                <p style={{ fontSize: "12px" }} className="text-primary">
                  Upgrade for less than $7 / month
                </p>
              </div>
            </div>
          </div>
        </Drawer>
      </div>

      {/* Modal for pricing upgrade */}
      <Modal
        open={isModalOpen}
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={null}
        width={837}
        className="upgrade-plans"
      >
        <PricingUpgrade />
      </Modal>
    </>
  );
};

export default Sidebar;
