import React from "react";
import quote from "../../../assets/images/home/quoteIcon.png";


const UserCard = ({data}) => {
  return (
    <>
      <div className="userCardContainer">
        <div className="leftPart">
          <img src={data.userImg} alt="" />
        </div>
        <div className="rightPart">
            <img className="quote" src={quote} alt="" />
       
          <p className="text-small">{data.sliderDate}</p>
          
          <h6 className="fw-semibold">{data.personName}</h6>
          <p className="text-small fw-normal">
        {data.details}
          </p>
        </div>
      </div>
    </>
  );
};

export default UserCard;
