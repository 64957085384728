import React, { useState, useEffect } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import feedback from "../../../assets/icons/feedback.svg";
import axios from 'axios';
import verifyAdmin from "../../../middleware/verifyAdmin";

const Settings = () => {
  const [activestate, setActiveState] = useState("profile");
  const [isAdmin, setIsAdmin] = useState(false); // State to track if the user is an admin
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdminStatus = async () => {
      const adminStatus = verifyAdmin();
      setIsAdmin(adminStatus);
    };
    checkAdminStatus();
  }, []);

  const handleProfileClick = () => {
    setActiveState("profile");
  };

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${process.env.REACT_APP_BASE_URL}/logout`, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      localStorage.removeItem('token');
      
      document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      
      // Navigate to the login page
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <div className="settingWrapper">
      <h4 className="fw-bold dark-gray">Settings</h4>
      <div className="tabSection mt-3">
        <div className="tabInnerContent d-flex">
          <div className="tabLeftContent d-flex flex-column ">
            <NavLink
              to="/settings/profile"
              onClick={handleProfileClick}
            >
              <Icon
                icon="ci:user-01"
                className="iconColor"
                width="18"
                height="18"
                style={{ color: "black" }}
              />
              <span className="">Profile</span>
            </NavLink>

            <NavLink
              to="/settings/account"
              onClick={handleProfileClick}
            >
              <Icon
                icon="ri:user-follow-line"
                className="iconColor"
                width="18"
                height="18"
                style={{ color: "black" }}
              />
              <span>Account</span>  
            </NavLink>

            <NavLink
              to="/settings/userfeedback"
            >
              <img className="iconColor" src={feedback} alt="" />
              <span className="ps-1">User Feedback</span>
            </NavLink>

            <NavLink
              to="/settings/bill"
              onClick={handleProfileClick}
            >
              <Icon
                icon="la:file-invoice"
                className="iconColor"
                width="18"
                height="18"
                style={{ color: "black" }}
              />
              <span>Billing</span> 
            </NavLink>

            {isAdmin && (
              <>
                <NavLink
                  to="/user-manage"
                >
                  <Icon
                    icon="ph:users-three"
                    width="18"
                    height="18"
                    style={{ color: "black" }}
                  />
                  <span> User</span>
                </NavLink>

                <NavLink
                  to="/feedback"
                >
                  <img className="iconColor" src={feedback} alt="" />
                  <span className="ps-1">Feedback</span>
                </NavLink>
              </>
            )}

            <button className="navlinks logout" onClick={handleLogout} style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}>
              <Icon
                icon="heroicons-outline:logout"
                width="18"
                height="18"
                style={{ color: "#FF3F3F" }}
              />
              <span className="text-red">Logout</span>
            </button>
          </div>

          <div className="tabRightContent w-100">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
