import React, { useState } from "react";
import footerlogo from "../assets/images/home/footerLogo.svg";
import Input from "./input";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Buttoon from "../components/buttoon";
import facbook from "../assets/icons/facebookIcon.png";
import youtube from "../assets/icons/youtubeIcon.svg";
import insta from "../assets/icons/instaIcon.svg";
import { scroller } from "react-scroll";

const Footer = () => {
  const [email, setemail] = useState("");
  const navigate = useNavigate();

  const navigateToHomeSection = (section, offset = 0) => {
    navigate("/");
    setTimeout(() => {
      scroller.scrollTo(section, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: offset,
      });
    }, 100);
  };
  return (
    <>
      <div className="footerSection">
        <div className="container">
          <div className="inner">
            <div className="leftArea">
              <img src={footerlogo} alt="" />
            </div>
            <div className="rightArea">
              <p className="text-small fw-normal mb-3 text-left">
              Sign up to find out more about new updates and features to ChatKYC!
              </p>
              <div className="inputDiv v-center gap-3">
                <Input
                  wrapperClass=" rounded   w-75"
                  placeholder="your@email.com"
                  labelClass="bg-dangers small medium bg-transparent text-black-50"
                  inputClass="fw-medium text-black "
                  value={email}
                  height="43px"
                  type="email"
                  className="ftrEmail input-fieldd "
                  onChange={(e) => setemail(e.target.value)}
                />

                <Link to="/">
                  <Buttoon
                    className="btn btn-primary heroBtn"
                    minWidth={104}
                    minHeight={43}
                    text="Send"
                  ></Buttoon>
                </Link>
              </div>
              <div className="w-100 socialIcons v-center h-center gap-2">
                <img className="cursor-pointer" src={facbook} alt="" />
                <img  className="cursor-pointer"  src={youtube} alt="" />
                <img  className="cursor-pointer"  src={insta} alt="" />
              </div>
            </div>
          </div>
          <div className="bottomSection">
            <div className="leftBtmSection">
              <ul className="text-small fw-medium v-center">
                <NavLink className="text-black cursor-pointer">
                  <li>PRODUCT</li>
                </NavLink>
                <a className="text-black cursor-pointer">
                  <li
                    onClick={() => navigateToHomeSection("Testimonials", -100)}
                  >
                    TESTIMONIAL
                  </li>
                </a>

                <a className="text-black cursor-pointer">
                  <li onClick={() => navigateToHomeSection("feature", -100)}>
                    FEATURES
                  </li>
                </a>

                <NavLink className="text-black cursor-pointer" to="/pricing">
                  <li>PRICING</li>
                </NavLink>

                <a className="text-black cursor-pointer">
                  <li onClick={() => navigateToHomeSection("feature", -100)}>
                    FAQ's
                  </li>
                </a>
              </ul>
            </div>
            <div className="rightBtmSection">
              <p className="text-small fw-medium">
                Copyright © 2024 • Chat KYC.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
